import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tipografia",
      "style": {
        "position": "relative"
      }
    }}>{`Tipografia`}</h1>
    <p>{`A escala tipográfica é usada para definir uma hierarquia entre grupos de conteúdo e orientar o usuário pela interface. A tipografia do Bold é organizada em 5 níveis de títulos, corpo, botões e links, possuindo cores e pesos (regulares e negritos) variados.`}</p>
    <h2 {...{
      "id": "familia-tipografica",
      "style": {
        "position": "relative"
      }
    }}>{`Família tipográfica`}</h2>
    <p>{`A família tipográfica utilizada é a `}<a parentName="p" {...{
        "href": "https://www.ibm.com/plex/"
      }}>{`IBM Plex Sans`}</a>{` por sua versatilidade e legibilidade, além de ser um tipo de fonte de código aberto.`}</p>
    <h2 {...{
      "id": "escalas-de-tipografia",
      "style": {
        "position": "relative"
      }
    }}>{`Escalas de tipografia`}</h2>
    <table>
  <tbody>
    <tr>
      <td>0.8125 rem</td>
      <td>13 px</td>
      <td>
        <h5 style={{
              margin: 0
            }}>H5 - Não faça design para designers, faça design para pessoas</h5>
      </td>
    </tr>
    <tr>
      <td>0.875 rem</td>
      <td>14 px</td>
      <td>
        <h4 style={{
              margin: 0
            }}>H4 - Não faça design para designers, faça design para pessoas</h4>
      </td>
    </tr>
    <tr>
      <td>1 rem</td>
      <td>16 px</td>
      <td>
        <h3 style={{
              margin: 0
            }}>H3 - Não faça design para designers, faça design para pessoas</h3>
      </td>
    </tr>
    <tr>
      <td>1.25 rem</td>
      <td>20 px</td>
      <td>
        <h2 style={{
              margin: 0
            }}>H2 - Não faça design para designers, faça design para pessoas</h2>
      </td>
    </tr>
    <tr>
      <td>1.5 rem</td>
      <td>24 px</td>
      <td>
        <h1 style={{
              margin: 0
            }}>H1 - Não faça design para designers, faça design para pessoas</h1>
      </td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      